<template>
  <main class="index-page">
    <section class="manual">
      <h3>
        Расскажем как посмотреть онлайн аниме вместе с друзьями одновременно на расстоянии
      </h3>
      <p class="desc">
        <strong>Посмотреть любимое аниме онлайн вместе с другом на расстоянии</strong> вы можете именно у нас!
        Всего в несколько кликов создаем комнату и делимся ссылкой на нее с другом! И это все!
        Время таймлайна всех участников комнаты автоматически синхронизируется с её владельцем.
        Но также каждый из зрителей может самостоятельно управлять плеером: перемотка, плей, пауза, громкость и качество видео.
      </p>
      <ul class="table">
        <li class="item">
          <!-- <figure v-if="isDesktop">
            <figcaption>
              1) <strong>Ищем аниме</strong>, которое хотим посмотреть
            </figcaption>
            <a href="/static_files/cinema/1.jpeg" target="_blank">
              <img :data-src="'/static_files/cinema/1.jpeg'" src="/static_files/no-poster.png" class="lazy" alt="Создание комнаты для совместного просмотра - изображение 1" />
            </a>
          </figure> -->
          <div>
            <span class="fig">1) <strong>Ищем аниме</strong>, которое хотим посмотреть</span>
            <SH :type="`rooms`" />
          </div>
        </li>
        <li class="item">
          <figure>
            <figcaption>
              2) <strong>Выбираем из выпадающего списка аниме,</strong> которое нам подходит
            </figcaption>
            <a href="/static_files/cinema/2.jpeg" target="_blank">
              <img :data-src="'/static_files/cinema/2.jpeg'" src="/static_files/no-poster.png" class="lazyload" alt="Создание комнаты для совместного просмотра - изображение 2" />
            </a>
          </figure>
        </li>
        <li class="item">
          <figure>
            <figcaption>
              3) Вот и все! Создание комнаты завершено. Теперь самое главное - приглашаем друзей!
            </figcaption>
            <a href="/static_files/cinema/3.jpeg" target="_blank">
              <img :data-src="'/static_files/cinema/3.jpeg'" src="/static_files/no-poster.png" class="lazyload" alt="Создание комнаты для совместного просмотра - изображение 3" />
            </a>
            <a href="/static_files/cinema/4.jpeg" target="_blank">
              <img :data-src="'/static_files/cinema/4.jpeg'" src="/static_files/no-poster.png" class="lazyload" alt="Создание комнаты для совместного просмотра - изображение 3" />
            </a>
          </figure>
        </li>
      </ul>

      <section class="index-desc">
        <h1>Совместный просмотр аниме онлайн с друзьями	на расстоянии</h1>

        <figure>
          <img src="/static_files/no-poster.png" :data-src="isDesktop ? `/static_files/index-page.jpg` : `/static_files/index-page_mobile.jpg` " class="lazyload" alt="Совместный просмотр аниме вместе с другом на расстоянии">
          <figcaption>Смотрите онлайн лучшие аниме вместе на Animeast</figcaption>
        </figure>

        <div>
          <p>Добро пожаловать на наш сайт, посвященный совместному просмотру аниме! Мы рады предложить вам уникальную возможность насладиться самыми популярными аниме-сериалами в компании единомышленников.</p>

          <p>Совместный просмотр аниме – это увлекательное и захватывающее занятие, которое позволяет зрителям насладиться анимацией и сюжетом произведения вместе с другими любителями аниме. Наш сайт создан специально для тех, кто хочет найти новых друзей, обсудить любимые моменты и просто получить удовольствие от общения с единомышленниками.</p>

          <p>Что вы найдете на нашем сайте? Во-первых, у нас есть огромная коллекция самых популярных аниме-сериалов. Мы постоянно обновляем нашу библиотеку, чтобы вы могли насладиться последними новинками и классическими шедеврами жанра. Вы можете выбрать любимый сериал и присоединиться к активности, чтобы обсудить каждую серию, поделиться своими впечатлениями и узнать мнение других зрителей.</p>

          <p>Во-вторых, мы предлагаем удобную систему поиска, которая позволяет вам быстро найти интересующий вас аниме-сериал. Вы можете фильтровать по жанрам, году выпуска, студии-разработчику и другим параметрам, чтобы найти именно то, что вам по вкусу. Наша цель – сделать поиск аниме максимально простым и удобным для вас.</p>

          <p>Третья особенность нашего сайта – это возможность создавать и принимать участие в групповых просмотрах аниме. Вы можете создать свою группу и пригласить друзей, чтобы смотреть аниме вместе. Мы предлагаем удобные инструменты для общения и синхронизации просмотра, чтобы вы могли наслаждаться анимацией и одновременно делиться впечатлениями с друзьями.</p>

          <p>Наш сайт абсолютно бесплатен и открыт для всех любителей аниме. Мы стремимся создать дружественное и уютное сообщество, где каждый найдет себе место. Присоединяйтесь к нам, делитесь своими эмоциями и находите новых друзей, которые разделяют вашу страсть к аниме!</p>

          <p>Не упустите возможность насладиться аниме-сериалами вместе с единомышленниками. Зарегистрируйтесь на нашем сайте прямо сейчас и присоединяйтесь к увлекательным групповым просмотрам аниме!</p>
        </div>
      </section>
    </section>
  </main>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    SH: () => import('~/components/SH.vue'),
  },
  computed: mapState(['isDesktop']),
  // async asyncData({ $axios }) {
  //   const { data } = await $axios.get('/api/viewpost')
  //   return {
  //     items: data
  //   }
  // },
}
</script>

<style src="~/assets/index.styl" lang="stylus" />
